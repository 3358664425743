const Colors = {
  white: '#ffffff',
  light: '#eeeeee',
  lightGrey: '#e5dada',
  dark: '#006f87',
  darker: '#006f87',
  darkest: '#00796b',
  closeIcon: '#969ea4'
};

export default Colors;
